<template>
  <div>
    <van-cell class="search-menu" @click="showPopup">
      <van-icon name="wap-nav" size="27" />
    </van-cell>
    <van-popup
      v-model="show"
      class="ii"
      position="left"
      :style="{ height: '100%', width: '20rem' }"
      closeable
    >
      <div class="header">
        <router-link
          v-show="hasLogin"
          :to="'/personDetail/' + $store.getters.getUser.id"
          class="user-head"
        >
          <img :src="avatar" alt="" />
        </router-link>
        <van-icon
          v-show="!hasLogin"
          name="user-circle-o"
          size="50"
          @click="goLogin"
        />
        <div class="user-name">
          <div v-show="hasLogin" class="user-id">{{ username }}</div>
          <router-link to="/login" v-show="!hasLogin" class="user-id">{{
            msg
          }}</router-link>
        </div>
      </div>
      <div v-show="hasLogin">
        <div class="person-follow" @click="goFollowers">
          <span style="font-weight: bold">
            {{ this.$store.getters.getUser.following }}
          </span>

          <div>Following</div>

          <span style="font-weight: bold">
            {{ this.$store.getters.getUser.follower }}
          </span>

          <div>Followers</div>
        </div>

        <div class="selectList">
          <van-cell-group inset>
            <!-- <van-cell  to="/MyMessage" title="我的通知" clickable icon="bell"/> -->
            <van-cell to="/MyUpload" title="我的上传" clickable icon="column" />
            <van-cell
              to="/MyCollection"
              title="我的收藏"
              clickable
              icon="star"
            />
            <van-cell to="/Edit" title="编辑个人信息" icon="setting" />
            <van-cell v-show="hasLogin" @click="logout" clickable>
              <template #title>
                <van-icon class="iconfont" class-prefix="icon" name="liyuan" />
                <span style="margin-left: 5px" class="custom-title"
                  >退出登录</span
                >
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {
      show: false,
      msg: "请点击登录",
      username: "",
      avatar: "",
      hasLogin: false,
    };
  },
  methods: {
    goLogin() {
      this.$router.push("/login");
    },

    goFollowers() {
      this.$router.push("/Follower");
    },

    showPopup() {
      this.show = true;
    },
    logout() {
      const _this = this;
      _this.$axios
        .get("/logout", {
          Headers: {
            Authorization: localStorage.getItem("token"),
          },
        })
        .then((res) => {
          _this.$store.commit("REMOVE_INFO");
          _this.$router.push("/login");
        });
    },
  },
  created() {
    console.log(this.$store.getters.getUser.nickname);

    if (this.$store.getters.getUser.username) {
      this.username = this.$store.getters.getUser.nickname;
      if (this.$store.getters.getUser.avatar) {
        this.avatar = this.$store.getters.getUser.avatar;
      } else {
        this.avatar = "https://b.yzcdn.cn/vant/icon-demo-1126.png";
      }
      this.hasLogin = true;
    } else {
      this.avatar = "https://b.yzcdn.cn/vant/icon-demo-1126.png";
    }
  },
};
</script>
  
  <style scoped>
.search-menu {
  position: absolute;
  top: 2px;
  width: 60px;
  height: 45px;
  z-index: 21;
}

.ii {
  background: #f8f8f8;
}

.selectList {
  margin-top: 10px;
}

.header {
  margin-top: 70px;
  display: flex;
  margin-left: 23px;
  width: 85%;
  height: 50px;
}

.user-head {
  width: 55px;
  height: 55px;
}

.user-head img {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.user-id {
  color: black;
  font-weight: 600;
  font-size: 17px;
}
.user-name {
  margin-left: 5px;
  color: black;
  margin: 15px;
  font-size: 16px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.person-follow {
  display: flex;
  font-size: 14px;
  margin-top: 5px;
  margin-left: 8px;
  padding: 15px;
}

.person-follow div {
  color: rgb(113, 115, 129);
  margin-left: 4px;
  margin-right: 10px;
}
</style>