<template>
  <div class="mainHeader">
    <Nav> </Nav>
    <!-- 搜索栏  -->
    <div class="search-Wrap">
      <!-- 导航区域 -->
      <div class="tapCard">
        <div class="main-card">
          <van-tabs
            @click="onClick"
            :fixed="false"
            class="top-tabCard"
            inactive-color="#000000"
            v-model="active"
            animated
            swipeable
          >
            <van-tab class="taps" title="关注">
              <div class="toLogin" v-show="isShowFollow">
                <h3>你还未登录</h3>
                <div style="color: darkgray">登录账号，查看你关注的内容</div>
                <van-button type="info" to="/login" round size="large"
                  >登录</van-button
                >
              </div>
              <Follow v-show="!isShowFollow" />
            </van-tab>

            <van-tab class="taps" title="最新">
              <New v-show="true" />
            </van-tab>

            <van-tab class="taps" title="热门">
              <Hot />
            </van-tab>
          </van-tabs>
        </div>
      </div>

      <div class="BtnSearch">
        <router-link to="/Search" class="search"></router-link>
        <Add></Add>
      </div>
    </div>
  </div>
</template>

<script scoped>
import New from "../Blogs/New.vue";
import Hot from "../Blogs/Hot.vue";
import Follow from "../Blogs/Follow.vue";

import Nav from "../Nav/index.vue";
import Add from "../Add/index.vue";
export default {
  name: "Header",
  components: {
    Nav,
    Add,
    New,
    Hot,
    Follow,
  },
  data() {
    return {
      active: 1,
      isShowFollow: true,
    };
  },
  props: ["pageIndex"],

  methods: {
    onClick(name, title) {
      // console.log(name)
      // console.log(title)
    },
  },

  mounted() {
    if (localStorage.getItem("token")) {
      // console.log(localStorage.getItem("token"))
      this.isShowFollow = false;
    }
  },
};
</script>

<style>
.toLogin {
  width: 85%;
  margin: 0 auto;
  margin-top: 80px;
  height: 100vh;
}

.toLogin h3 {
  text-align: center;
}

.toLogin div {
  margin-bottom: 20px;
  text-align: center;
}

.search-Wrap {
  overflow: hidden;
  position: relative;
  background-color: white;
}

.BtnSearch {
  position: absolute;
  top: 0;
  right: 0;
  width: 100px;
  height: 40px;
  /* background-color: cadetblue; */
}

.search {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 40px;
  z-index: 20;
}

.add {
  position: absolute;
  top: 0;
  right: 0;
  width: 50%;
  height: 40px;
  z-index: 20;
}

.search::before {
  content: "";
  display: block;
  width: 31px;
  height: 31px;
  background: url("../../assets/images/search.png") no-repeat;
  background-size: 100%;
  margin: 10px 0px 0 18px;
}

/* 搜索栏 */
/* .main-search {
    width: 100%;
    background-color: #1195db;
} */

.tapCard {
  /* position: absolute; */
  height: 100%;
  width: 100%;
}
.taps {
  font-size: 17px;
}

.van-tab--active {
  color: #247bee;
  font-weight: 500;
}

.highlight {
  position: absolute;
  top: 6px;
  left: 50px;
  border: none;
}

/* 导航栏 */
.main-card {
  position: relative;

  border-radius: 2px;
}

.top-tabCard div {
  /* margin-left: auto;
    margin-right: auto; */
  font-size: 16px;
  text-decoration: none;
}

.van-tab {
  height: 50px;
}

.van-tabs__line {
  background-color: #247bee;
}

.van-tabs--line .van-tabs__wrap {
  height: 50px;
}

.van-tabs__nav--line {
  margin-right: auto;
  margin-left: auto;
  width: 58%;
  box-sizing: content-box;
  height: 100%;
  padding-bottom: 15px;
}
</style>