<template>
  <div>
        <div v-show="$store.getters.getUser.id"  class="add" >
            <van-uploader 
            :after-read="afterRead" 

            >
                <div class="add1"> </div>
            </van-uploader>
        </div>

        <div class="add" v-show="!$store.getters.getUser.id" @click="beforeRead">
            <div class="add1"> </div>
        </div>
        <div >
            <img :src="pic" alt="" >
        </div>
  </div>
</template>

<script>
import { Dialog } from 'vant';
export default {
    data() {
        return {
            show: false,
            pic:""
        };
    },

    methods: {

        beforeRead(){
            Dialog.confirm({
                confirmButtonColor: "#0099FF",
                cancelButtonColor: "#0099FF",
                confirmButtonText: "前去登录",
                message: '您还没有权限哦，请先登录!' 
            }).then(()=>{
                this.$router.push("/login")
            }).catch(()=>{
                
            })

        },
        afterRead(file) {   

        // 此时可以自行将文件上传至服务器
            const that = this
            // console.log(file)
            that.pic = file.content
            //sessionStorage.setItem("pic1",that.pic)
            this.goMark(that.pic)
            

        },

        
        goMark(pic){
            this.$router.push({
                name:"markRoute",
                params:{
                    pic: pic
                }
            })

        } 
    }
}
</script>

<style scoped> 

    .add {
        position:absolute;
        margin-top: 10px;
        right: 0;
        width: 35px;
        height: 51px;
        z-index: 20;
        line-height: 51px;
    }

    .add1::before {
        content: "";
        display: block;
        width: 28px;
        height: 28px;
        background: url('../../assets/images/round_add.png') no-repeat;
        background-size: 100%;
        /* margin:10px 0px 0 13px; */
        
    }

</style>