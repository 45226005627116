<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了~"
        @load="onLoad"
        class="user-poster"
        id="user-poster"
      >
        <van-cell
          class="user-root"
          @click="goDetail(index)"
          v-for="(p, index) in list"
          :key="index"
        >
          <!-- 用户头像和id -->
          <div class="header">
            <div @click.stop class="user-head">
              <router-link :to="'/personDetail/' + p.userId">
                <img :src="p.avatar" alt="" />
              </router-link>
            </div>

            <div class="user-name">
              <div class="user-id">{{ p.nickname }}</div>
              <div class="user-time">{{ p.created }}</div>
            </div>
          </div>

          <div class="user-content">
            <span @click.stop="goSearch(p.difficulty)" style="color: dodgerblue"
              >#{{ p.difficulty }}
            </span>
            <span
              @click.stop="goSearch(p.location)"
              style="color: dodgerblue; margin-left: 8px"
            >
              #{{ p.location }}</span
            >
          </div>

          <div class="user-contents">
            {{ p.content }}
          </div>

          <div class="user-photo">
            <img :src="p.pic" alt="" @click.stop="getImage(index)" />
          </div>
          <div class="icons">
            <div style="display: flex">
              <van-icon
                @click.stop="CollectRoute(p, index)"
                v-show="!temp[index]"
                name="star-o"
                size="21"
              />
              <van-icon
                @click.stop="unCollectRoute(p, index)"
                v-show="temp[index]"
                color="#FFD700"
                name="star"
                size="21"
              />
              <span v-show="!p.collectCounts">收藏</span>
              <span style="font-size: 15px" v-show="p.collectCounts">
                {{ p.collectCounts }}
              </span>
            </div>

            <div style="display: flex">
              <van-icon style="margin-top: 2px" name="comment-o" size="21" />
              <span v-show="!p.commentCounts"> 评论 </span>
              <span style="font-size: 15px" v-show="p.commentCounts">
                {{ p.commentCounts }}
              </span>
            </div>

            <div style="display: flex">
              <van-icon
                @click.stop="LikeRoute(p, index)"
                v-show="!temp1[index]"
                name="good-job-o"
                size="22"
              />
              <van-icon
                @click.stop="unLikeRoute(p, index)"
                v-show="temp1[index]"
                color="#e93855"
                name="good-job"
                size="22"
              />
              <span v-show="!p.likesCounts">点赞</span>
              <span style="font-size: 15px" v-show="p.likesCounts">
                {{ p.likesCounts }}
              </span>
            </div>
          </div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
  </div>
</template>




<script>
import { ImagePreview } from "vant";
import { Toast } from "vant";
import { Dialog } from "vant";
// import '@vant/doc/helper/touch-simulator';
export default {
  name: "Main",
  data() {
    return {
      isCollect: false,

      finished: false,
      refreshing: false,
      isLike: false,
      loading: false,
      collections: [],
      likes: [],
      temp: [],
      temp1: [],
      upLoadings: {},
      posterInfo: [],
      list: [],
      k: 0,
      t: 5,
      pageIndex: 2,
    };
  },

  methods: {
    getImage(index) {
      ImagePreview([this.posterInfo[index].pic]);
    },

    goSearch(item) {
      this.$router.push({
        name: "search",
        params: {
          value: item,
          show: false,
        },
      });
    },

    post() {
      const _this = this;
      _this.$axios.get("/blogs").then((res) => {
        _this.posterInfo = res.data.data.records;
        const temp = _this.posterInfo;
        var lastExchangeIndex = 0;
        var sortBorder = temp.length - 1;
        for (let i = 0; i < temp.length - 1; i++) {
          let isSorted = true;

          for (let j = 0; j < sortBorder; j++) {
            const element = temp[j];

            var a =
              element.collectCounts +
              element.likesCounts +
              element.commentCounts;
            let element1 = temp[j + 1];
            var b =
              element1.collectCounts +
              element1.likesCounts +
              element1.commentCounts;
            if (a < b) {
              isSorted = false;
              let t = temp[j];
              temp[j] = temp[j + 1];
              temp[j + 1] = t;
              lastExchangeIndex = j;
            }
          }
          sortBorder = lastExchangeIndex;
          if (isSorted) {
            break;
          }
        }
        _this.posterInfo = temp;
      });
    },

    goDetail(index) {
      this.$router.push({
        name: "detail",
        params: {
          posterId: this.posterInfo[index].id,
          isCollect: this.temp[index],
        },
      });
    },

    onLoad() {
      setTimeout(() => {
        if (this.refreshing) {
          this.list = [];
          this.k = 0;
          this.t = 5;
          this.refreshing = false;
        }

        if (this.posterInfo.length < this.t) {
          this.list = this.posterInfo;
        } else {
          for (let i = this.k; i < this.k + 5; i++) {
            this.list.push(this.posterInfo[i]);
          }

          this.k = this.k + 5;
          this.t = this.t + 5;
        }

        this.loading = false;

        if (
          this.list.length >= this.posterInfo.length &&
          this.posterInfo.length != 0
        ) {
          this.finished = true;
        }

        //收藏

        this.temp = [];
        this.list.forEach((a) => {
          if (this.collections.length == 0) {
            this.temp.push(false);
          }

          for (let k = 0; k < this.collections.length; k++) {
            const b = this.collections[k];
            if (a.id == b.articleId) {
              this.temp.push(true);
              break;
            } else if (k == this.collections.length - 1) {
              this.temp.push(false);
            }
          }
        });

        //点赞

        this.temp1 = [];
        this.list.forEach((a) => {
          if (this.likes.length == 0) {
            this.temp1.push(false);
          }

          for (let k = 0; k < this.likes.length; k++) {
            const b = this.likes[k];
            if (a.id == b.articleId) {
              this.temp1.push(true);
              break;
            } else if (k == this.likes.length - 1) {
              this.temp1.push(false);
            }
          }
        });
      }, 1000);
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.post();
      this.getCollects(this.$store.getters.getUser.id);
      this.isLiked(this.$store.getters.getUser.id);
    },

    collectCount(p) {
      p.collectCounts = p.collectCounts + 1;
      this.$axios.post("/blog/editComments", {
        id: p.id,
        commentCounts: p.commentCounts,
        collectCounts: p.collectCounts,
        likeCounts: p.likesCounts,
      });
    },

    CollectRoute(p, index) {
      if (!this.$store.getters.getUser.id) {
        Dialog.confirm({
          confirmButtonColor: "#0099FF",
          cancelButtonColor: "#0099FF",
          confirmButtonText: "前去登录",
          message: "您还没有权限哦，请先登录!",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      } else {
        this.isCollect = true;
        this.$axios
          .post(
            "/collection/addCollect",
            {
              articleId: p.id,
              userId: this.$store.getters.getUser.id,
            },
            {
              headers: { Authorization: localStorage.getItem("token") },
            }
          )
          .then((res) => {
            Toast("收藏成功！");
            this.collectCount(p);
            this.temp[index] = true;
          });
      }
    },

    unCollectCount(p) {
      this.isCollect = false;
      p.collectCounts = p.collectCounts - 1;
      this.$axios.post("/blog/editComments", {
        id: p.id,
        commentCounts: p.commentCounts,
        collectCounts: p.collectCounts,
        likesCounts: p.likesCounts,
      });
    },

    unCollectRoute(p, index) {
      this.$axios
        .post("/collection/cancelCollect", {
          articleId: p.id,
          userId: this.$store.getters.getUser.id,
        })
        .then((res) => {
          this.unCollectCount(p);
          Toast("已取消");
          this.temp[index] = false;
        });
    },

    getCollects(user_id) {
      this.$axios.get("/collection/collects/" + user_id).then((res) => {
        this.collections = res.data.data;
      });
    },

    likeCount(p) {
      p.likesCounts = p.likesCounts + 1;
      this.$axios
        .post("/blog/editComments", {
          id: p.id,
          commentCounts: p.commentCounts,
          collectCounts: p.collectCounts,
          likesCounts: p.likesCounts,
        })
        .then((res) => {
          // console.log("点赞完后的posterinfo",this.posterInfo)
        });
    },

    LikeRoute(p, index) {
      if (!this.$store.getters.getUser.id) {
        Dialog.confirm({
          confirmButtonColor: "#0099FF",
          cancelButtonColor: "#0099FF",
          confirmButtonText: "前去登录",
          message: "您还没有权限哦，请先登录!",
        })
          .then(() => {
            this.$router.push("/login");
          })
          .catch(() => {});
      } else {
        this.isLike = !this.isLike;
        this.$axios
          .post(
            "/like/addLike",
            {
              articleId: p.id,
              userId: this.$store.getters.getUser.id,
            },
            {
              headers: { Authorization: localStorage.getItem("token") },
            }
          )
          .then((res) => {
            this.likeCount(p);
            this.temp1[index] = !this.temp1[index];
            Toast("已点赞");
          });
      }
    },

    unLikeRoute(p, index) {
      this.isLike = !this.isLike;
      this.$axios
        .post("/like/cancelLike", {
          articleId: p.id,
          userId: this.$store.getters.getUser.id,
        })
        .then((res) => {
          this.unLikeCount(p);
          Toast("已取消");
          this.temp1[index] = !this.temp1[index];
        });
    },

    unLikeCount(p) {
      p.likesCounts = p.likesCounts - 1;
      this.$axios.post("/blog/editComments", {
        id: p.id,
        commentCounts: p.commentCounts,
        collectCounts: p.collectCounts,
        likesCounts: p.likesCounts,
      });
    },

    isLiked(user_id) {
      this.$axios.get("/like/likes/" + user_id).then((res) => {
        this.likes = res.data.data;
      });
    },
  },

  mounted() {
    this.post();
    if (this.$store.getters.getUser.id != null) {
      this.getCollects(this.$store.getters.getUser.id);
      this.isLiked(this.$store.getters.getUser.id);
    }

    // this.onRefresh();
  },
};
</script>
  
<style scoped>
.user-poster {
  width: 100%;
  height: auto;
  box-sizing: border-box;
  background-color: white;
  color: black;
}

.user-root {
  /* border-radius: 25px; */
  overflow: hidden;
  border-top: solid 0.5px #e6e6e6;
}

.header {
  display: flex;
  height: 45px;
}

.user-head {
  position: absolute;
  margin: 0;
  width: 45px;
  height: 45px;
  /* background-color: rgb(68, 138, 138); */
}

.user-head img {
  width: 100%;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
}

.user-name {
  position: absolute;
  left: 58px;
  color: black;
  font-weight: 700;
  font-size: 15px;
}
.user-time {
  color: #b7b7b7;
  font-size: 12px;
  margin: 2px;
}

.user-content {
  margin-left: 61px;
  margin-top: 4px;
  color: black;
  width: 85%;
  float: left;
  font-size: 14px;
  display: flex;
  /* background-color: yellow; */
}

.user-content div {
  position: relative;
  float: left;
}

.user-contents {
  margin-left: 63px;
  margin-bottom: 10px;
  color: black;
  width: 85%;
  font-size: 16px;
  display: flex;
  /* background-color: yellow; */
}

.user-photo {
  display: flex;
  margin-left: 60px;
  margin-bottom: 10px;
  width: 82%;

  /* background-color: wheat; */
}

.user-photo img {
  border: 0.5px solid rgb(168, 168, 168);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.search-login a {
  text-decoration: none;
  color: black;
}

.icons {
  position: relative;

  z-index: 10;
  display: flex;
  height: 30px;
  width: 95%;
  display: flex;
  right: 0;
  margin: 0 auto;
  margin-left: 35px;
}

.icons div {
  width: 33%;
  /* -webkit-align-items: center;
      align-items: center;
    -webkit-justify-content: center;
      justify-content: center; */
  margin: 5px 20px 5px 10px;
}

.icons i {
  margin-left: 10px;
  position: absolute;
}
.icons span {
  position: absolute;
  z-index: 10;
  margin-left: 40px;
}
</style>